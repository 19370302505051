import { useEffect, useState } from 'react'

import { FeedbackType } from '../../../domain/feedback'
import {
  FadeInOutContainer,
  FadeOutContainer,
  HtIcon,
  HtSnackbar,
  HtTooltip,
  Icon,
  Size,
} from '../base'
import { TooltipPlacement } from '../base/ht-tooltip/ht-tooltip-styles'
import { ConnectionStatusContainer } from './header-styles'

interface ConnectionStatusProps {
  hasNetworkIcon: boolean
  isOffline: boolean
  isConnectionInstable: boolean
}

export const ConnectionStatus = ({
  hasNetworkIcon,
  isConnectionInstable,
  isOffline,
}: ConnectionStatusProps): JSX.Element => {
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false)

  useEffect(() => {
    setIsSnackbarVisible(isConnectionInstable)
  }, [isConnectionInstable])

  return (
    <>
      <HtTooltip
        tooltip='Your connection seems to not be working. Saving and publishing will only be enabled when connection is reestablished.'
        isDisabled={isSnackbarVisible || !isOffline}
        placement={TooltipPlacement.BOTTOM}
      >
        <ConnectionStatusContainer>
          <FadeInOutContainer $isActive={!isOffline && hasNetworkIcon}>
            <HtIcon icon={Icon.CLOUD_CHECK} size={Size.LARGE} />
          </FadeInOutContainer>
          <FadeOutContainer $isActive={isOffline}>
            <HtIcon icon={Icon.CLOUD_SLASH} size={Size.LARGE} />
          </FadeOutContainer>
        </ConnectionStatusContainer>
      </HtTooltip>
      {isConnectionInstable && isSnackbarVisible && (
        <HtSnackbar
          title='Your connection seems to not be working.'
          text='Saving and publishing will only be enabled when connection is reestablished.'
          type={FeedbackType.CONNECTION_ISSUE}
          onClose={() => setIsSnackbarVisible(false)}
        />
      )}
    </>
  )
}
