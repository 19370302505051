import { getDateTime } from '../../../utils/date-utils'
import { TrackEventName, useAnalytics } from '../../analytics'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { PopupType } from '../../types'
import { HtButton, HtIcon, Icon, Size } from '../base'
import { VersionHistoryData } from '../header/version-history-dropdown/version-history-dropdown'
import { NotificationContainer } from './version-banner-styles'

interface VersionBannerProps {
  version: VersionHistoryData
  restoreDraftFlow: () => Promise<void>
}

export const VersionBanner = ({
  version,
  restoreDraftFlow,
}: VersionBannerProps) => {
  const analytics = useAnalytics()
  const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)

  const getTitle = () => {
    const dateTitle = `Published on ${getDateTime(version.createdAt)}`
    if (!version.user) return dateTitle
    return `${dateTitle} (${version.user.getUserName()})`
  }

  const restoreVersion = () => {
    analytics.trackEvent(TrackEventName.VERSION_CLICK_RESTORE_CTA)
    setPopupContent({ type: PopupType.RESTORE_VERSION })
  }

  const onCancel = () => {
    analytics.trackEvent(TrackEventName.VERSION_CLICK_CLOSE)
    restoreDraftFlow()
  }

  return (
    <NotificationContainer>
      {getTitle()}
      <HtButton
        size={Size.TINY}
        onClick={evt => {
          evt.stopPropagation()
          restoreVersion()
        }}
      >
        Restore to this version
      </HtButton>
      <HtIcon icon={Icon.XMARK} size={Size.MEDIUM} onClick={onCancel} />
    </NotificationContainer>
  )
}
