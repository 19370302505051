import { State } from '../../types'
import { ChangesHistory } from '../action-types'

export abstract class ReversibleAction {
  protected static updateChangesHistory = (
    state: State,
    newChange: ChangesHistory,
    isChangeSaveable = true
  ): void => {
    if (state.isReadOnly) return
    state.changeHistory = state.changeHistory.splice(0, state.historyIndex + 1)
    state.historyIndex = state.changeHistory.length
    state.changeHistory.push(newChange)
    if (isChangeSaveable) {
      state.hasUnsavedChanges = true
    }
  }
}
