import { DragEvent, useMemo, useReducer } from 'react'
import { Connection, EdgeSelectionChange, ReactFlowInstance } from 'reactflow'
import { useContextSelector } from 'use-context-selector'

import { FeedbackMessage } from '../../domain/feedback'
import { BotConfig } from '../../domain/models/bot-config-models'
import {
  PayloadFields,
  TopContentFields,
  UrlFields,
} from '../../domain/models/content-fields'
import { Locale, NewLocale } from '../../domain/models/locales/locale'
import { SUPPORTED_LOCALES } from '../../domain/models/locales/supported-locales'
import { OrganizationAiModelWithIntents } from '../../domain/models/organization-models'
import { FALLBACK_FLOW, MAIN_FLOW } from '../constants'
import {
  ComputedPreviousFlow,
  Flow,
  NodeTypes,
  NonMessageContents,
  OrganizationContents,
  PopupContent,
  State,
  Webview,
} from '../types'
import { ActionType } from './action-types'
import { FlowBuilderContext, FlowBuilderContextProps } from './context'
import { reducer } from './reducer'

export const initialState: State = {
  nodes: [],
  isLocalesPanelOpen: false,
  locales: [SUPPORTED_LOCALES.english],
  nonMessageContents: { urls: [], payloads: [] },
  organizationContents: {
    projects: [],
    aiModels: [],
    featureFlags: {},
    knowledgeSources: [],
    conversationalApp: {},
  },
  botVariables: [],
  feedback: [],
  authToken: '',
  isReadOnly: false,
  currentLocale: SUPPORTED_LOCALES.english,
  isKnowledgeBaseActive: false,
  hasUnsavedChanges: false,
  changeHistory: [],
  historyIndex: -1,
  currentFlowId: MAIN_FLOW.id,
  flows: [MAIN_FLOW, FALLBACK_FLOW],
  computedPreviousFlows: [
    { id: MAIN_FLOW.id, previousFlows: [], showPreviousFlows: false },
    { id: FALLBACK_FLOW.id, previousFlows: [], showPreviousFlows: false },
  ],
  webviews: [],
  hashPublished: '',
  hash: '',
}

export const useFlowBuilderSelector = <T>(
  selector: (context: FlowBuilderContextProps) => T
): T => {
  return useContextSelector(FlowBuilderContext, selector)
}

export function useFlowBuilder(): FlowBuilderContextProps {
  const [state, dispatch] = useReducer(reducer, initialState)

  const selectEdges = (changes: EdgeSelectionChange[]) => {
    dispatch({ type: ActionType.SELECT_EDGES, changes })
  }

  const connectNodes = (connection: Connection) => {
    dispatch({ type: ActionType.CONNECT_NODES, connection })
  }

  const copyElements = (nodesToCopy: NodeTypes[], event: ClipboardEvent) => {
    dispatch({ type: ActionType.COPY_ELEMENTS, nodesToCopy, event })
  }

  const cutElements = (nodesToCut: NodeTypes[], event: ClipboardEvent) => {
    dispatch({ type: ActionType.CUT_ELEMENTS, nodesToCut, event })
  }

  const nodeDragStart = (nodes: NodeTypes[]) =>
    dispatch({ type: ActionType.NODE_DRAG_START, nodes })

  const nodeDragStop = (nodesToReposition: NodeTypes[]) =>
    dispatch({ type: ActionType.NODE_DRAG_STOP, nodesToReposition })

  const nodeDrop = (event: DragEvent) =>
    dispatch({ type: ActionType.NODE_DROP, event })

  const pasteElements = (event: ClipboardEvent) => {
    dispatch({ type: ActionType.PASTE_ELEMENTS, event })
  }

  const removeEdgesById = (ids: string[]) => {
    dispatch({ type: ActionType.REMOVE_EDGES_BY_ID, ids })
  }

  const removeNodes = (nodesToRemove: NodeTypes[]) => {
    dispatch({ type: ActionType.REMOVE_NODES, nodesToRemove })
  }

  const removeFeedbackMessages = (messages?: FeedbackMessage[]) => {
    dispatch({ type: ActionType.REMOVE_FEEDBACK_MESSAGES, messages })
  }

  const selectNode = (node: NodeTypes) => {
    dispatch({ type: ActionType.SELECT_NODE, node })
  }

  const setOrganizationContents = (
    organizationContents: OrganizationContents
  ) => {
    dispatch({
      type: ActionType.SET_ORGANIZATION_CONTENTS,
      organizationContents,
    })
  }

  const toggleInteractivity = (isInteractive: boolean) => {
    dispatch({ type: ActionType.TOGGLE_INTERACTIVITY, isInteractive })
  }

  const selectAiModel = (aiModel?: OrganizationAiModelWithIntents) => {
    dispatch({ type: ActionType.SELECT_AI_MODEL, aiModel })
  }

  const selectLocale = (locale: Locale, isReversible?: boolean) => {
    dispatch({ type: ActionType.SELECT_LOCALE, locale, isReversible })
  }

  const resetChanges = () => dispatch({ type: ActionType.RESET_CHANGES })

  const setPopupContent = (content?: PopupContent) =>
    dispatch({ type: ActionType.SET_POPUP_CONTENT, content })

  const setReactFlowRefs = (
    reactFlowInstance: ReactFlowInstance,
    reactFlowWrapper: React.RefObject<HTMLDivElement>
  ) =>
    dispatch({
      type: ActionType.SET_REACT_FLOW_REFS,
      reactFlowInstance,
      reactFlowWrapper,
    })

  const setAuthToken = (authToken: string) =>
    dispatch({ type: ActionType.SET_AUTH_TOKEN, authToken })

  const setLocales = (locales: Locale[]) => {
    dispatch({ type: ActionType.SET_LOCALES, locales })
  }

  const addLocales = (newLocales: NewLocale[]) => {
    dispatch({ type: ActionType.ADD_LOCALES, newLocales })
  }

  const removeLocales = (localesToRemove: Locale[]) => {
    dispatch({ type: ActionType.REMOVE_LOCALES, localesToRemove })
  }

  const setBotVariables = (botVariables: string[]) => {
    dispatch({ type: ActionType.SET_BOT_VARIABLES, botVariables })
  }

  const addPayload = (newPayload: PayloadFields) => {
    dispatch({ type: ActionType.ADD_PAYLOAD, newPayload })
  }

  const removePayload = (payloadToRemove: PayloadFields) => {
    dispatch({ type: ActionType.REMOVE_PAYLOAD, payloadToRemove })
  }

  const addUrl = (newUrl: UrlFields) => {
    dispatch({ type: ActionType.ADD_URL, newUrl })
  }

  const removeUrl = (urlToRemove: UrlFields) => {
    dispatch({ type: ActionType.REMOVE_URL, urlToRemove })
  }

  const editUrl = (urlToEdit: UrlFields, newName: string) => {
    dispatch({ type: ActionType.EDIT_URL, urlToEdit, newName })
  }

  const setSelectedNodes = (nodeIds: string[]) =>
    dispatch({ type: ActionType.SET_SELECTED_NODES, nodeIds })

  const toggleLocalesPanel = (toggle: boolean) =>
    dispatch({ type: ActionType.TOGGLE_LOCALES_PANEL, toggle })

  const closeNodeEditor = () => dispatch({ type: ActionType.CLOSE_NODE_EDITOR })

  const addFeedbackMessage = (message: FeedbackMessage) => {
    dispatch({ type: ActionType.ADD_FEEDBACK_MESSAGE, message })
  }

  const updateAllContents = (
    nodes?: NodeTypes[],
    nonMessageContents?: NonMessageContents
  ) => {
    dispatch({
      type: ActionType.UPDATE_ALL_CONTENTS,
      nodes,
      nonMessageContents,
    })
  }

  const updateNode = (data: TopContentFields) => {
    dispatch({ type: ActionType.UPDATE_NODE, data })
  }

  const setKnowledgeBaseActive = (
    isKnowledgeBaseActive: boolean,
    isReversible?: boolean
  ) => {
    dispatch({
      type: ActionType.SET_KNOWLEDGE_BASE_ACTIVE,
      isKnowledgeBaseActive,
      isReversible,
    })
  }

  const undo = () => {
    dispatch({ type: ActionType.UNDO })
  }

  const redo = () => {
    dispatch({ type: ActionType.REDO })
  }

  const restoreChangeHistory = () => {
    dispatch({ type: ActionType.RESTORE_CHANGE_HISTORY })
  }

  const selectFlow = (flowId: string, isReversible?: boolean) => {
    dispatch({ type: ActionType.SELECT_FLOW, flowId, isReversible })
  }

  const setFlows = (flows: Flow[]) => {
    dispatch({ type: ActionType.SET_FLOWS, flows })
  }

  const setComputedPreviousFlows = (
    computedPreviousFlows: ComputedPreviousFlow[]
  ) => {
    dispatch({
      type: ActionType.SET_COMPUTED_PREVIOUS_FLOWS,
      computedPreviousFlows,
    })
  }

  const addFlow = (newFlow: Flow) => {
    dispatch({ type: ActionType.ADD_FLOW, newFlow })
  }

  const removeFlow = (flowToRemove: Flow) => {
    dispatch({ type: ActionType.REMOVE_FLOW, flowToRemove })
  }

  const updateAllNodes = (newNodes: NodeTypes[], isReversible?: boolean) => {
    dispatch({ type: ActionType.UPDATE_ALL_NODES, newNodes, isReversible })
  }

  const addWebview = (newWebview: Webview) => {
    dispatch({ type: ActionType.ADD_WEBVIEW, newWebview })
  }

  const removeWebview = (webviewToRemove: Webview) => {
    dispatch({ type: ActionType.REMOVE_WEBVIEW, webviewToRemove })
  }

  const setWebviews = (webviews: Webview[]) => {
    dispatch({ type: ActionType.SET_WEBVIEWS, webviews })
  }

  const selectWebview = (webviewId: string) => {
    dispatch({ type: ActionType.SELECT_WEBVIEW, webviewId })
  }

  const setHashPublished = (hashPublished: string) => {
    dispatch({ type: ActionType.SET_HASH_PUBLISHED, hashPublished })
  }

  const setHash = (hash: string) => {
    dispatch({ type: ActionType.SET_HASH, hash })
  }

  const setBotConfig = (botConfig: BotConfig) => {
    dispatch({
      type: ActionType.SET_BOT_CONFIG,
      botConfig,
    })
  }

  return {
    state,
    ...useMemo(
      () => ({
        addLocales,
        addFlow,
        addPayload,
        addUrl,
        addWebview,
        selectEdges,
        closeNodeEditor,
        connectNodes,
        copyElements,
        cutElements,
        editUrl,
        nodeDragStart,
        nodeDragStop,
        nodeDrop,
        pasteElements,
        redo,
        removeEdgesById,
        removeFlow,
        removeLocales,
        removeNodes,
        removePayload,
        removeFeedbackMessages,
        removeUrl,
        removeWebview,
        resetChanges,
        restoreChangeHistory,
        selectAiModel,
        selectFlow,
        selectLocale,
        selectNode,
        selectWebview,
        setBotConfig,
        setBotVariables,
        setFlows,
        setComputedPreviousFlows,
        setKnowledgeBaseActive,
        setLocales,
        setOrganizationContents,
        setPopupContent,
        setReactFlowRefs,
        setSelectedNodes,
        setAuthToken,
        setWebviews,
        toggleInteractivity,
        toggleLocalesPanel,
        addFeedbackMessage,
        undo,
        updateAllContents,
        updateAllNodes,
        updateNode,
        setHashPublished,
        setHash,
      }),
      []
    ),
  }
}
