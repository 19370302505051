import { AnalyticsBrowser } from '@segment/analytics-next'
import { FC } from 'react'

import { AnalyticsContext } from './context'

export const AnalyticsProvider: FC<{
  analytics: AnalyticsBrowser
  children: JSX.Element
}> = ({ children, analytics }) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}
