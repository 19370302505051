import { State } from '../../types'
import { actionMap } from '../action-map'
import { ChangesHistory } from '../action-types'
import { NodeAction } from './node-actions/node-action'

export class UndoAction {
  static apply = (state: State) => {
    const changeToUndo = state.changeHistory[state.historyIndex]
    if (!changeToUndo) return
    this.applyChange(state, changeToUndo)
    this.updateCurrentNode(state, changeToUndo)
    state.hasUnsavedChanges = true
    state.historyIndex--
  }

  private static updateCurrentNode = (state: State, change: ChangesHistory) => {
    if ('currentFlowId' in change) {
      state.currentFlowId = change.currentFlowId
    }
    if ('currentNode' in change) {
      if (change.currentNode) {
        NodeAction.selectNode(state, change.currentNode)
      } else {
        state.currentNode = undefined
      }
    }
  }

  private static applyChange = (state: State, change: ChangesHistory) => {
    const actionClass = actionMap[change.type]
    if (actionClass) {
      actionClass.undo(state, change as any)
    }
  }
}
