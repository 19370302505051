import { memo } from 'react'

import { Locale } from '../../../domain/models/locales/locale'
import { TrackEventName, useAnalytics } from '../../analytics'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ROLES } from '../../roles'
import {
  DropdownProps,
  HtBtnType,
  Placement,
  Size,
  TextBodyBold,
} from '../base'
import { HorizontalContainer } from '../base/containers'
import { AiSettingsDropdown } from './ai-settings-dropdown/ai-settings-dropdown'
import {
  FlowActionButtons,
  FlowActionButtonsProps,
} from './flow-action-buttons'
import { HeaderContainer } from './header-styles'
import { LocaleDropdown } from './locale-dropdown/locale-dropdown'
import {
  VersionHistoryData,
  VersionHistoryDropdown,
} from './version-history-dropdown/version-history-dropdown'

export const headerDropdownProps: Omit<DropdownProps, 'label'> = {
  btnProps: {
    type: HtBtnType.BORDERLESS_BLACK,
    size: Size.SMALL,
    width: 'fit-content',
  },
  listProps: {
    maxListHeight: '450px',
    hasItemsWithBorder: true,
    itemSize: Size.LARGE,
    listPlacement: Placement.BOTTOM_END,
  },
  hideLabel: true,
}

export interface HeaderProps extends FlowActionButtonsProps {
  versionHistoryData: VersionHistoryData[]
  loadPublishedVersion(version: VersionHistoryData): Promise<void>
}

export const Header = memo(
  ({
    hasActionsDisabled,
    versionHistoryData,
    loadPublishedVersion,
    ...actionButtonsProps
  }: HeaderProps): JSX.Element => {
    const analytics = useAnalytics()
    const conversationalApp = useFlowBuilderSelector(
      ctx => ctx.state.organizationContents.conversationalApp
    )

    const { selectLocale, removeFeedbackMessages } = useFlowBuilderSelector(
      ctx => ctx
    )

    const changeLocale = async (locale: Locale) => {
      removeFeedbackMessages()
      selectLocale(locale)
      await analytics.trackEvent(TrackEventName.CHANGE_LANGUAGE, {
        language_selected: locale.code,
      })
    }

    return (
      <HeaderContainer role={ROLES.BANNER}>
        <TextBodyBold>{conversationalApp.name}</TextBodyBold>
        <HorizontalContainer $size={Size.LARGE}>
          <AiSettingsDropdown />
          <LocaleDropdown changeLocale={changeLocale} />
          <VersionHistoryDropdown
            versionHistoryData={versionHistoryData}
            loadPublishedVersion={loadPublishedVersion}
          />
        </HorizontalContainer>
        <FlowActionButtons
          {...actionButtonsProps}
          hasActionsDisabled={hasActionsDisabled}
        />
      </HeaderContainer>
    )
  }
)
